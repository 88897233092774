.donation-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.donation-container1 {
  width: 100%;
  height: 504px;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
}
.donation-slider {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.donation-slider-slide {
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1698778574083-279be0ac6681?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY5OTk3NzU2N3w&ixlib=rb-4.0.3&q=80&w=1500');
  background-repeat: no-repeat;
}
.donation-slider-slide1 {
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1697384333613-de519c7367c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY5OTk3NzAzN3w&ixlib=rb-4.0.3&q=80&w=1500');
  background-repeat: no-repeat;
}
.donation-slider-slide2 {
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1698180687511-bd6c0104ee98?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTY5OTk3NzQ5NXw&ixlib=rb-4.0.3&q=80&w=1500');
  background-repeat: no-repeat;
}
.donation-text2 {
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
}
.donation-slider-pagination {
  display: block;
}
.donation-container2 {
  width: 100%;
  height: 467px;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #ffffff;
  border-width: 1px;
  justify-content: center;
}
@media(max-width: 1600px) {
  .donation-container1 {
    height: 691px;
  }
  
  .donation-slider {
    height: 687px;
  }
}
@media(max-width: 1200px) {
  .donation-container {
    padding: 0px;
  }
  .donation-container1 {
    width: 100%;
    height: 689px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 0px;
    flex-direction: row;
    justify-content: center;
  }
  .donation-slider {
    width: 1196px;
  }
}
@media(max-width: 991px) {
  .donation-slider {
    height: 723px;
  }
}
@media(max-width: 767px) {
  .donation-slider {
    height: 585px;
  }
}
@media(max-width: 479px) {
  .donation-slider {
    height: 334px;
  }
}
