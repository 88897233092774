.footer-container {
  width: 100%;
  height: 119px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #121212;
}
.footer-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: 26px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.footer-link {
  color: #ffffff;
}
.footer-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-link1 {
  color: #ffffff;
}
.footer-link2 {
  color: #ffffff;
}
.footer-container3 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-icon {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.footer-icon2 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.footer-icon4 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
