.newbtn-container {
  display: flex;
  position: relative;
}
.newbtn-button {
  box-shadow: 5px 5px 9px 0px #4c4c4c;
  transition: 0.3s;
  border-width: 0px;
  border-radius: 0px;
  background-color: #7d230d;
  
}

.newbtn-button:active {
  background-color: #ffc002;
}



