.donate-sld-container {
  width: 520px;
  height: 445px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.donate-sld-container1 {
  width: 100%;
  height: 444px;
  display: flex;
  align-items: center;
  border-color: #dee2e6;
  border-width: 2px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: #f8f9fa;
}
.donate-sld-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.donate-sld-container2 {
  width: 100%;
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.donate-sld-button {
  transition: 0.3s;
  border-color: #0d6efd;
  border-right-width: 0px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.donate-sld-button:hover {
  background-color: #0d6efd;
}
.donate-sld-button1 {
  transition: 0.3s;
  border-color: #0d6efd;
  border-radius: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.donate-sld-button1:hover {
  background-color: #0d6efd;
}
.donate-sld-button2 {
  transition: 0.3s;
  border-color: #0d6efd;
  border-left-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.donate-sld-button2:hover {
  background-color: #0d6efd;
}
.donate-sld-container3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 156px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.donate-sld-button3 {
  transition: 0.3s;
  border-color: #0d6efd;
}
.donate-sld-button3:hover {
  background-color: #0d6efd;
}
.donate-sld-text2 {
  text-align: center;
}

@media(max-width: 1600px) {
  .donate-sld-container {
    width: 535px;
    height: 443px;
  }
  .donate-sld-container1 {
    width: 100%;
    height: 100%;
  }
}
@media(max-width: 1200px) {
  .donate-sld-container {
    width: 500px;
    height: 393px;
  }
  .donate-sld-container1 {
    width: 100%;
    height: 100%;
  }
  .donate-sld-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }
}
@media(max-width: 767px) {
  .donate-sld-container3 {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}